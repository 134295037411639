<template>
  <div class="page">
    <div class="page-main">
      <!--      页面-->
      <div class="page-header">
        <el-button
          id="btn-create"
          type="primary"
          size="small"
          @click="showPopup('create')"
        >create</el-button>
        <!--        <el-button id="btn-update" @click="showPopup('update')" :disabled="updateButtonDisabled">update</el-button>-->
        <el-button
          id="btn-delete"
          type="primary"
          size="small"
          :disabled="deleteButtonDisabled"
          @click="delete_()"
        >delete</el-button>
      </div>
      <div class="table">
        <table-pagination
          :tableData="table.data"
          :columnData="table.column"
          :total="table.total"
          :currentPage.sync="table.currentPage"
          :pageSize.sync="table.pageSize"
          selectionShow
          @selection-change="selectionChange"
          @changeCurrentPage="refresh"
        >
          <!-- 多选框 -->
          <el-table-column
            :if="selectionShow"
            type="selection"
            min-width="40px"
          ></el-table-column>
        </table-pagination>
      </div>
      <!--      弹窗-->
      <el-dialog class="create" :visible.sync="isShowCreatePopup">
        <el-form>
          <el-upload
            class="upload-demo"
            name="multipartFile"
            action="/tb-firmware/upload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="table.fileList"
            :on-success="onSuccess"
          >
            <el-button size="small" type="primary">Click to upload</el-button>
          </el-upload>
          <el-form-item label="firmwareName">
            <el-input v-model="dialog.create.firmwareName"></el-input>
          </el-form-item>
          <el-form-item label="firmwareVersion">
            <el-input v-model="dialog.create.firmwareVersion"></el-input>
          </el-form-item>
          <el-form-item label="firmwareDesc">
            <el-input v-model="dialog.create.firmwareDesc"></el-input>
          </el-form-item>

          <el-form-item label="supportModel">
            <el-input v-model="dialog.create.supportModel"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('create')" @disabled = "!dialog.create.uploadFileUrl || !dialog.create.fileSize">Submit</el-button>
            <el-button @click="closePopup('create')">Cancel</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog class="update" :visible.sync="isShowUpdatePopup">
        <el-form>
          <el-form-item label="firmwareName">
            <el-input v-model="dialog.update.firmwareName"
                      v-bind:placeholder="dialog.updatePlaceholder.firmwareName"></el-input>
          </el-form-item>
          <el-form-item label="firmwareVersion">
            <el-input v-model="dialog.update.firmwareVersion"
                      v-bind:placeholder="dialog.updatePlaceholder.firmwareVersion"></el-input>
          </el-form-item>
          <el-form-item label="firmwareDesc">
            <el-input v-model="dialog.update.firmwareDesc"
                      v-bind:placeholder="dialog.updatePlaceholder.firmwareDesc"></el-input>
          </el-form-item>
          <el-form-item label="supportModel">
            <el-input v-model="dialog.update.supportModel"
                      v-bind:placeholder="dialog.updatePlaceholder.supportModel"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('update')">Submit</el-button>
            <el-button @click="closePopup('update')">Cancel</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios.js'
import {TablePagination} from '@/components'
import {firmwareRemoveBatch} from '@/api/firmware'

export default {
  name: 'FirmwareAdmin',
  components: {
    TablePagination
  },
  data() {
    return {
      updateButtonDisabled: true,
      deleteButtonDisabled: true,
      selectionShow: false,
      table: {
        data: [],
        column: [
          {
            label: 'firmwareName',
            prop: 'firmwareName',
            minWidth: 150,
          },
          {
            label: 'firmwareVersion',
            prop: 'firmwareVersion',
            minWidth: 150,
          },
          {
            label: 'firmwareDesc',
            prop: 'firmwareDesc',
            minWidth: 150,
          },
          {
            label: 'supportModel',
            prop: 'supportModel',
            minWidth: 150,
          }
        ],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        fileList: []
      },
      searchKey: String,
      isShowCreatePopup: false,
      isShowUpdatePopup: false,
      dialog: {
        create: {
          firmwareName: null,
          firmwareVersion: null,
          firmwareDesc: null,
          supportModel: null,
          uploadFileUrl: null,
        },
        update: {
          id: null,
          firmwareName: null,
          firmwareVersion: null,
          firmwareDesc: null,
          supportModel: null,
          uploadFileUrl: null
        },
        updatePlaceholder: {
          firmwareName: null,
          firmwareVersion: null,
          firmwareDesc: null,
          supportModel: null,
          uploadFileUrl: null
        }

      },
      selected: null
    }
  },
  computed: {},
  methods: {
    refresh() {
      this.clean()
      this.read()
    },
    create() {
      this.dialog.create.firmwareSize = this.dialog.create.fileSize
      axios.post('/tb-firmware/addFirmwareForAdmin', this.dialog.create).then(() => {
        this.closePopup('create')
        this.$message.success('successful')
        this.refresh()
      })
    },
    read() {
      let params = {
        keywords: this.searchKey,
        pageSize: this.table.pageSize,
        currentPage: this.table.currentPage,
        firmwareType: 0,
        sortField: 'updateTime',
        sortOrder: 'desc'
      }
      axios.post('/tb-firmware/selectFrimware', params).then((data) => {
        this.table.data = data.data.result.rows
        this.table.total = data.data.result.totalRows
      })
    },
    update() {
      axios.post('/tb-firmware/addFirmwareForAdmin', this.dialog.update).then(() => {
        this.dialog.update = {}
        this.closePopup('update')
        this.$message.success('successful')
        this.refresh()
      })
    },
    delete_() {
      const ids = this.selected.map((e) => e.id),
        args = this.selected.map((e) => e.firmwareName)
      firmwareRemoveBatch({ ids, args })
        .then(() => {
          this.$message.success('successful')
          this.refresh()
        })
    },
    showPopup(name) {
      if (name === 'create') {
        this.isShowCreatePopup = true
      } else if (name === 'update') {
        this.fillId()
        this.fillPlaceholder()
        this.isShowUpdatePopup = true
      }
    },
    submitForm(name) {
      if (name === 'create') {
        this.create()
      } else if (name === 'update') {
        this.update()
      }
    },
    closePopup(name) {
      if (name === 'create') {
        this.isShowCreatePopup = false
      } else if (name === 'update') {
        this.isShowUpdatePopup = false
      }
    },
    selectionChange(selected) {
      this.selected = selected
      if (this.selected.length === 1) {
        this.updateButtonDisabled = false
      } else {
        this.updateButtonDisabled = true
      }

      if (this.selected.length < 1) {
        this.deleteButtonDisabled = true
      } else {
        this.deleteButtonDisabled = false
      }
    },
    fillPlaceholder() {
      this.dialog.updatePlaceholder = (this.selected)[0]
    },
    fillId() {
      this.dialog.update.id = (this.selected)[0].id
    },
    clean() {
      this.dialog.create = {
        firmwareName: null,
        firmwareVersion: null,
        firmwareDesc: null,
        site: null,
        supportModel: null,
        uploadFileUrl: null
      }
      this.dialog.create.uploadFileUrl = null
      this.table.fileList = []
    },
    handlePreview() {

    },
    handleRemove() {

    },
    beforeRemove() {

    },
    handleExceed() {
      this.$message.warning('exceed limit 1 file')
    },
    onSuccess(response) {
      let { uploadUrl, fileSize, identify } = response.result.rows[0]
      this.dialog.create.uploadFileUrl = uploadUrl
      this.dialog.create.fileSize = fileSize
      this.dialog.create.identify = identify
    },
  },

  mounted() {
    this.refresh()
  },
}
</script>

<style lang="scss" scoped>
.page-main {
  .el-dialog {
    //width: 30%;
  }
}
</style>
