<template>
  <div class="page">
    <div class="page-main">
      <el-form class="form-max-900" ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item
          :label="$t('firmware.uploadFile')"
          prop="uploadFile"
          class="form-item-upload-file"
        >
          <div class="form-item-width">
            <el-upload
              class="upload"
              drag
              ref="upload"
              action="/tb-resources/uploadFile?type=otherResources"
              name="multipartFile"
              :headers="{ 'Accept-Language': language == 'en' ? 'en-US' : language }"
              :before-upload="beforeUpload"
              :on-change="onChange"
              :file-list="form.uploadFile"
              :on-remove="onRemove"
              :on-success="onSuccess"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                {{ $t('components.upload.tip1') }}
                <em>{{ $t('components.upload.tip2') }}</em>
              </div>
              <div
                class="el-upload__tip"
                slot="tip"
                v-html="$t('components.upload.tip3', [this.type(), this.size()])"
              ></div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item :label="$t('otherResources.fileType')" prop="fileType">
          <dist-select v-model="form.fileType" :optionData="OtherResourcesTypes" :disabled="isDisableChangeType()"></dist-select>
        </el-form-item>

        <el-form-item :label="$t('edm001')" prop="resourceName">
          <el-input
              v-model="form.resourceName"
              :placeholder="$t('edm006')"
              :maxlength="128"
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('ssite.site')" prop="siteIds">
          <select-site v-model="form.siteIds" checkboxShow checkStrictly></select-site>
        </el-form-item>
        <el-form-item :label="$t('edm002')" prop="description">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              :placeholder=" $t('edm007')"
              :maxlength="500"
              v-model="form.description">
          </el-input>
        </el-form-item>
        <el-form-item class="footer-btns">
          <el-button
            class="footer-btn"
            @click="cancel"
          >{{ $t('cancel') }}</el-button>
          <el-button
            class="footer-btn"
            type="primary"
            @click="submit"
          >{{ $t('save') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { resourcesQuery, addResources } from '@/api/otherResources'
import { SelectSite, DistSelect } from '@/components'
import { fileCheck } from '@/plugins/methods'

export default {
  name: 'OtherResourcesDetails',
  components: {
    SelectSite,
    DistSelect,
  },
  data() {
    return {
      fileTypes: ['.flac', '.gsm', '.ogg', '.wav', '.mp3', '.gsrt', '.bin', '.jpg', '.png', '.txt'],
      loading: false,
      form: {
        uploadFile: [],
        fileUrl: '',
        fileName: '',
        fileType: "1",
        siteIds: [],
        fileSize: '',
      },
      rules: {
        uploadFile: {
          required: true,
          message: this.$t('firmware.uploadFileRule'),
          trigger: 'change',
        },
        fileName: {
          required: true,
          message: this.$t('otherResources.fileNameNull'),
          trigger: 'blur',
        },
        resourceName:{
          required: true,
          message: this.$t('otherResources.resourceNameNull'),
        },
        fileType: {
          required: true,
        },
        siteIds: {
          required: true,
          message: this.$t('otherResources.siteNull'),
          trigger: 'change',
        },
      },
      groupTypeList: [],
      pageType: '',
      oldResourceId:''
    }
  },
  computed: {
    ...mapState('memory', ['language']),
    ...mapGetters('dist', ['OtherResourcesTypes','FileTypeToResourceTypes','SizeLimitToResourceTypes1','SizeLimitToResourceTypes2']),
  },
  created() {
    this.$route.query.id && this.getData(this.$route.query.id)
    this.pageType = this.$route.query.pageType
    this.oldResourceId = this.$route.query.id
  },
  methods: {
    getData(id) {
      resourcesQuery({ id })
        .then((res) => {
          this.form = res.data.result.rows[0]
          this.$set(this.form, 'uploadFile', [
            { name: this.form.fileName },
          ])
        })
        .catch(() => {})
    },
    cancel() {
      this.$router.go(-1)
    },
    // 保存
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(JSON.stringify(this.form.uploadFile))
          let params = {
            resourceName: this.form.resourceName,
            fileUrl: this.form.fileUrl,
            fileName: this.form.uploadFile[0].name,
            fileType: this.form.fileType,
            siteIds: this.form.siteIds,
            fileSize: this.form.fileSize,
            description: this.form.description,
            id: this.form.id,
            pageType : this.pageType,
            oldResourceId : this.oldResourceId
          }
          addResources(params)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.cancel()
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    beforeUpload(file) {
      this.$refs.form.validateField('uploadFile')
      const fileType = fileCheck({
        file,
        fileTypes: this.type(),
        fileSize: this.size2(),
        callback: (typeErr, sizeErr) => {
          typeErr &&
            this.$message.error(
              this.$t('components.upload.typeTipsMsg', [this.type()])
            )
          !typeErr &&
            sizeErr &&
            this.$message.error(this.$t('components.upload.sizeTipsMsg', [this.size()]))
        },
      })
      return fileType
    },
    onChange(file, fileList) {
      this.form.uploadFile = [fileList[fileList.length - 1]]
    },
    onRemove(file, fileList) {
      this.form.uploadFile = fileList
      this.$refs.form.validateField('uploadFile')
    },
    onSuccess(response) {
      this.form.fileSize = response.result.rows[0].fileSize
      this.form.fileUrl = response.result.rows[0].uploadUrl
      this.form.id = response.result.rows[0].identify

      this.$refs.form.validateField('uploadFile')
    },
    size(){
      return this.SizeLimitToResourceTypes1[this.form.fileType];
    },
    size2(){
      return this.SizeLimitToResourceTypes2[this.form.fileType];
    },
    type(){
      return this.FileTypeToResourceTypes[this.form.fileType];
    },
    isDisableChangeType(){
      return this.$route.query.pageType == "edit";
    }
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page-main {
  padding: 30px;
}
.upload {
  ::v-deep.el-upload,
  ::v-deep.el-upload-dragger {
    width: 100%;
  }
  ::v-deep.el-list-enter-active,
  ::v-deep.el-list-leave-active {
    transition: none;
  }
  ::v-deep.el-list-enter,
  ::v-deep.el-list-leave-active {
    opacity: 0;
  }
  ::v-deep.el-upload-list {
    height: 40px;
  }
  ::v-deep.el-progress__text {
    display: none;
  }
  .form-item-upload-file ::v-deep.el-form-item__error {
    position: absolute;
    top: 182px;
  }
}
</style>
